import * as yup from "yup"
import classNames from "classnames"
import dayjs from "dayjs"

import { mapCurrencyTypeToCurrencySymbol } from "./analytics/dashboard/bankAccounts"
import {
  getCapitalLettersRegex,
  getNumbersRegex,
  getSmallLettersRegex,
  getSpecialCharactersRegex,
  IRAQ_DAILING_CODE,
  MAP_ARABIC_NUM_TO_LATIN_NUM,
  MAP_LATIN_NUM_TO_ARABIC_NUM,
  PASSWORD_ERROR_MISSING_LOWERCASE,
  PASSWORD_ERROR_MISSING_SPECIAL_CHAR,
  PASSWORD_ERROR_MISSING_UPPERCASE,
  PASSWORD_ERROR_SHORT_PASSWORD,
  PASSWORD_STRENGTH_AVERAGE,
  PASSWORD_STRENGTH_STRONG,
  PASSWORD_STRENGTH_WEAK,
  THOUSANDS_MATCHER_REGEX,
  VALID_FILE_NAME_REGEX,
} from "../shared/constants/general"

/**
 * returns {@link dayjs.Dayjs} object which represents the difference between the
 * date created from {@link ms} and this point in time.
 * @param {number} ms - number of milliseconds representing desired point in time.
 */
export const diffWithNow = (ms) => dayjs(dayjs(ms).diff(dayjs()))

/**
 * Auth Code is a 12-char string, this formats the
 * string so it'll be 3 slices each of 4 characters
 * delimitted by `-`.
 * @param {string} authCode to be formatted
 * @returns {string}
 */
export const formatAuthCode = (authCode) => {
  if (!authCode) return ""
  return authCode
    .split("")
    .map((c, i) => (i !== 0 && i % 4 === 0 ? `-${c}` : c))
    .join("")
}

/**
 * Returns comma depending dir is RTL or LTR.
 * @param {boolean} isRTL
 * @returns
 */
export const getCommaByDir = (isRTL) => (isRTL ? "،" : ",")

/**
 * builds a function to be used with react-router className
 * function variant to toggle `--active` modifier on
 * `modifiedClass` className.
 * @param {string} baseClassName
 * @param {string} modifiedClass
 */
export const activeNavlinkClassNameBuilder =
  (baseClassName, modifiedClass) =>
  /** @param {boolean} isActive */
  ({ isActive }) =>
    classNames(baseClassName, {
      [`${modifiedClass || baseClassName}--active`]: isActive,
    })

/**
 * converts Latin numbers in the string to Arabic
 * and returns the string and non-numeric characters
 * as they are.
 * @param {string} characters
 */
export const convertNumbersToArabic = (characters) =>
  characters
    .toString()
    .split("")
    .map((character) => MAP_LATIN_NUM_TO_ARABIC_NUM[character] || character)
    .join("")

/**
 * converts Arabic numbers in the string to Latin
 * and returns the string and non-numeric characters
 * as they are.
 * @param {string} characters
 */
export const convertNumbersToLatin = (characters) =>
  characters
    .split("")
    .map((character) => MAP_ARABIC_NUM_TO_LATIN_NUM[character] || character)
    .join("")

/**
 * takes a number and formats by seperating each 3 digit
 * with a comma `,`.
 * @param {number} [number=0]
 * @param {boolean} [isRTL=false]
 */
export const thousandFormatter = (number = 0, isRTL = false) => {
  const separator = getCommaByDir(isRTL)
  let formattedNumber = number
    .toString()
    .replace(THOUSANDS_MATCHER_REGEX, separator)

  if (isRTL) {
    formattedNumber = convertNumbersToArabic(formattedNumber)
  }
  return formattedNumber
}

/**
 * takes a number that is formatted
 * with a comma `,` and remove it.
 * @param {string} [formattedNumber="0"]
 * @param {boolean} [isRTL=false]
 */
export const removeThousandFormatter = (
  formattedNumber = "0",
  isRTL = false
) => {
  const separator = getCommaByDir(isRTL)
  let result = formattedNumber
    .split("")
    .filter((char) => char !== separator)
    .join("")
  if (isRTL) {
    result = convertNumbersToLatin(result)
  }
  return result
}

/**
 * Custom `prop-type` validator to validate props that should
 * be correct **dayjs** date object
 * @param {object} props
 * @param {string} propName
 * @param {string} componentName
 * @returns { boolean } whether the React prop with propName is valid
 * dayjs object or not.
 */
export const dayjsDateValidator = (props, propName, componentName) => {
  if (!dayjs.isDayjs(props[propName])) {
    return new Error(
      `Invalid props ${propName} supplied to ${componentName}. Please, provide valid dayjs instance.`
    )
  }

  return true
}

export const formatAmount = (
  amount,
  currency,
  isRTL,
  shouldAddSymbol = false
) => {
  let formattedAmount = thousandFormatter(amount, isRTL)
  const formattedCurrency = mapCurrencyTypeToCurrencySymbol(currency, isRTL)

  if (shouldAddSymbol && amount > 0) {
    formattedAmount = `+${formattedAmount}`
  }
  return `${formattedAmount} ${formattedCurrency}`
}

/**
 * formats phoneNumber depening on the direction
 * @param {string} text
 * @param {boolean} isRTL
 * @returns
 */
export const formatNumbers = (text, isRTL) =>
  isRTL ? convertNumbersToArabic(text) : text

/**
 * converts a "year-month-day" date to UTC
 * @param {string} formattedDate
 * @param {string} splitter
 * @returns {Date}
 */
export const convertFormattedDateToUTC = (formattedDate, splitter = "-") => {
  const [year, month, day] = formattedDate.split(splitter).map(Number)
  return new Date(Date.UTC(year, month - 1, day))
}

/**
 *
 * @param {string} phoneNumber
 * @param {string} countryCode
 * @param {boolean} isRTL
 * @returns {string}
 */
export const removeDailingCode = (
  phoneNumber = "",
  dailingCode = IRAQ_DAILING_CODE,
  isRTL = false
) => {
  let code = dailingCode
  const numbers = formatNumbers(phoneNumber, isRTL)

  if (isRTL) code = convertNumbersToArabic(dailingCode)

  return numbers.replace(code, "")
}

/**
 * Returns a new array that includes all items
 * in which the `id` isn't same as `otherId`
 * @param {object[]} array
 * @param {*} array.id
 * @param {*} otherId
 * @returns
 */
export const filterById = (array, otherId) =>
  array.filter(({ id: thisId }) => thisId !== otherId)

/**
 * Returns index of an item that it's `id` is
 * same as `otherId`
 * @param {object[]} array
 * @param {*} array.id
 * @param {*} otherId
 * @returns
 */
export const findIndexById = (array, otherId) =>
  array.findIndex(({ id: thisId }) => thisId === otherId)

/**
 * takes any items, stringifies each and joins them with `-`
 * as a hash
 *
 * @param  {...any} items
 */
export const hashItems = (...items) =>
  items.map((item) => JSON.stringify(item)).join("-")

/**

 *
 * @param  {...string} items
 */
export const parseItems = (...items) => items.map((item) => JSON.parse(item))

/**
 * takes a file name string and a limit, cuts the name from the middle.
 *
 * @param {string} text
 * @param {number} limit
 * @returns {{formattedText: string, originalText: string}}
 *
 * @example
 * formatFileName("salary-file-template-sample.csv", 25) => {formattedText: "salary-fi.....sample.csv", originalText: "salary-file-template-sample.csv"}
 */
export const formatFileName = (text, limit) => {
  let formattedText = text
  if (formattedText.length >= limit) {
    formattedText = `${formattedText.substring(
      0,
      limit - 15
    )}.....${formattedText.slice(formattedText.length - 10)}`
  }

  return {
    formattedText,
    originalText: text,
  }
}

/**
 * takes an event `e` and calls its `preventDefault` method.
 * @param {Event} e
 */
export const preventEventDefault = (e) => {
  e.preventDefault()
  e.returnValue = ""
}

/**
 * takes a string that is a number formatted
 * with a comma `,` and convert it to a number.
 * @param {string} amount
 * @param {boolean} isRTL
 * @returns {number}
 */

export const convertStringAmountToNumber = (amount, isRTL) =>
  Number(removeThousandFormatter(convertNumbersToLatin(amount), isRTL))

/**
 * takes a number and the its length of how many zero's it should add + the direction
 * to add and prepend zero's till the length has met.
 *
 * @param {number} num
 * @param {number} length
 * @param {boolean} isRTL
 * @returns {string}
 *
 * @example
 * digitPrepender(9, 2, false) => 09
 * @example
 * digitPrepender(9, 2, true) => .٩
 */
export const digitPrepender = (num, length, isRTL = false) => {
  let digits = isRTL ? convertNumbersToArabic(num) : num.toString()

  while (digits.length < length) {
    digits = `${isRTL ? "٠" : "0"}${digits}`
  }

  return digits
}

/**
 * Takes a password tries to unpack the information
 * about how strong the password is, and returns a
 * constant representing Weak, Average and Strong.
 * @param {string} [password = ""]
 * @returns { PASSWORD_STRENGTH_WEAK | PASSWORD_STRENGTH_AVERAGE | PASSWORD_STRENGTH_STRONG }
 */
export const getPasswordStrength = (password = "") => {
  if (password.length < 10) return PASSWORD_STRENGTH_WEAK

  const includeCapitalLetters = getCapitalLettersRegex().test(password)
  const includeSmallLetters = getSmallLettersRegex().test(password)
  const includeNumbers = getNumbersRegex().test(password)
  const includeSpecialCharacters = getSpecialCharactersRegex().test(password)

  if (
    includeCapitalLetters &&
    includeSmallLetters &&
    includeSpecialCharacters &&
    includeNumbers
  ) {
    return PASSWORD_STRENGTH_STRONG
  }

  if (
    (includeCapitalLetters && includeSmallLetters) ||
    (includeSpecialCharacters &&
      includeNumbers &&
      (includeSmallLetters || includeCapitalLetters))
  ) {
    return PASSWORD_STRENGTH_AVERAGE
  }

  return PASSWORD_STRENGTH_WEAK
}

export const passwordTooltipInstructionsSchema = yup
  .string()
  .test(
    "length >= 10",
    (value, context) =>
      value.length >= 10 ||
      context.createError({
        message:
          "web_c_terminals_createterminalmodal_password_mincharvalidation_label",
        type: PASSWORD_ERROR_SHORT_PASSWORD,
      })
  )
  .test(
    "contains uppercase",
    (value, context) =>
      getCapitalLettersRegex().test(value) ||
      context.createError({
        message: "web_c_terminals_createterminalmodal_uppercase_validation",
        type: PASSWORD_ERROR_MISSING_UPPERCASE,
      })
  )
  .test(
    "contains lowercase",
    (value, context) =>
      getSmallLettersRegex().test(value) ||
      context.createError({
        message: "web_c_terminals_createterminalmodal_lowercase_validation",
        type: PASSWORD_ERROR_MISSING_LOWERCASE,
      })
  )
  .test(
    "contains special character",
    (value, context) =>
      (getSpecialCharactersRegex().test(value) &&
        getNumbersRegex().test(value)) ||
      context.createError({
        message:
          "web_c_terminals_createterminalmodal_shouldhavespecialchar_validation",
        type: PASSWORD_ERROR_MISSING_SPECIAL_CHAR,
      })
  )
  .required()

export const passwordStrengthSchema = yup
  .string()
  .required("web_c_general_password_requiredvalidation")
  .max(64, "web_c_general_password_maxchar")
  .test(
    "must not be weak",
    (value, context) =>
      getPasswordStrength(value) !== PASSWORD_STRENGTH_WEAK ||
      context.createError({
        message: "web_c_general_password_strengthvalidation",
      })
  )

export const repeatedPasswordSchema = yup
  .string()
  .required("web_c_general_confirmpassword_required_validation")
  .test(
    "matches password",
    (value, context) =>
      value === context.parent.newPassword ||
      context.createError({
        message: "web_c_general_confirmpassword_shouldmatch_validation",
        type: "PASSWORD_MISMATCH",
      })
  )

export const passwordStepSchema = yup.object({
  newPassword: passwordStrengthSchema,
  repeatedPassword: repeatedPasswordSchema,
})

/**
 * Takes a string and returns a new string that contains
 * numerical characters (0-9) only.
 *
 * @param {string} value
 * @param {boolean} isRTL
 * @returns {string}
 * @example
 * purifyValueAsNumber("1230abcd", false) => 1230
 * purifyValueAsNumber("1230-+=a987", true) => ١٢٣٠٩٨٧
 */
export const purifyValueAsNumber = (value, isRTL) =>
  removeThousandFormatter(value, isRTL)
    .replace(/[^0-9]/g, "")
    .replace(/^([0]*)(.+)$/, "$2")

/**
 * takes a file name string, it will check the name based on the given expression
 * to see if the name contains any irregular charachters.
 *
 * @param {string} fileName
 * @returns {boolean}
 *
 * @example
 * isFileNameValid("تەست.csv") => false
 * isFileNameValid("custom-file_name.csv") => true
 */
export const isFileNameValid = (fileName) =>
  VALID_FILE_NAME_REGEX.test(fileName)

/**
 * takes an event and stops its propagation
 * @param {Event} event
 */
export const stopEventPropagation = (event) => {
  event.stopPropagation()
}

/**
 * takes role features and removes disabled property from the role
 * 
 * @param {import("../api/endpoints/roles").roleFeatures} roleFeatures
 */
export const removeDisabled = (roleFeatures) =>
  roleFeatures?.features?.map(({ permissions, ...item }) => {
    const updatedPermissions = permissions?.map((permission) => {
      const { disabled, ...updatedPermission } = permission
      return updatedPermission
    })

    return {
      ...item,
      permissions: updatedPermissions,
    }
  })
