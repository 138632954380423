import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  createRole,
  deleteRole,
  getRolesPermissionTemplate,
  getRoleDetails,
  getRoles,
  authorizeRole,
  setRoleApprovalThresholds,
  updateRole,
} from "../../api/endpoints/roles"
import {
  ROLE_ADDITION_OPERATION_ID,
  ROLE_ADDITION_STATE,
} from "../../shared/constants/roles/add-role"
import { ROLE_DELETION_STATE } from "../../shared/constants/roles/delete-role"
import {
  EDIT_ROLE_OPERATION_ID,
  EDIT_ROLE_STATE,
} from "../../shared/constants/roles/edit-role"

const SLICE_NAME = "roles"

export const roleAuthorization = createAsyncThunk(
  `${SLICE_NAME}/roleAuthorization`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken)
      throw new Error("web_c_general_notauthorized_error_text")

    const { id } = thunkAPI.getState().roles.roleDetails

    try {
      const { data, status, statusText } = await authorizeRole(id, {
        headers: { Authorization: `Bearer ${elevatedToken}` },
      })

      return { data, status, statusText }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const fetchRolePermissionsTemplate = createAsyncThunk(
  `${SLICE_NAME}/fetchRolePermissionsTemplate`,
  async () => {
    const { data, status, statusText } = await getRolesPermissionTemplate()
    return {
      data,
      status,
      statusText,
    }
  }
)

export const roleCreation = createAsyncThunk(
  `${SLICE_NAME}/roleCreation`,
  async (role) => {
    const { data, status, statusText } = await createRole(role)
    return {
      data,
      status,
      statusText,
    }
  }
)

export const editingRole = createAsyncThunk(
  `${SLICE_NAME}/editingRole`,
  async (role, thunkAPI) => {
    // NOTE we have to pass initialRoleDetails because when going back and forth between the steps
    // will result roleDetails.id to change and eventually causing bug of not editing role correctly
    const { id: roleId } = thunkAPI.getState().roles.editRole.initialRoleDetails

    const { data, status, statusText } = await updateRole(roleId, role)
    return {
      data,
      status,
      statusText,
    }
  }
)

export const roleApprovalThresholdsSetting = createAsyncThunk(
  `${SLICE_NAME}/roleApprovalThresholdsSetting`,
  async ({ roleId, name, approvalThresholds }) => {
    const { data, status, statusText } = await setRoleApprovalThresholds(
      roleId,
      {
        name,
        approvalThresholds,
      }
    )
    return {
      data,
      status,
      statusText,
    }
  }
)

export const fetchRoleDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchRoleDetails`,
  async (id) => {
    const { data, status, statusText } = await getRoleDetails(id)
    return {
      data,
      status,
      statusText,
    }
  }
)

export const fetchRoles = createAsyncThunk(
  `${SLICE_NAME}/fetchRoles`,
  async ({ page, size }) => {
    const { data, status, statusText } = await getRoles({
      page,
      size,
    })
    return { data, status, statusText }
  }
)

export const roleDeletion = createAsyncThunk(
  `${SLICE_NAME}/roleDeletion`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken)
      throw new Error("web_c_general_notauthorized_error_text")

    const { id } = thunkAPI.getState().roles.roleDeletion

    const { data, status, statusText } = await deleteRole(id, {
      headers: { Authorization: `Bearer ${elevatedToken}` },
    })
    return { data, status, statusText }
  }
)

/**
 * @typedef RoleDeletion
 * @property {import("../../shared/constants/roles/delete-role").RoleDeletionState} state
 * @property {string=} id
 * @property {string=} name
 * @property {import("@reduxjs/toolkit").SerializedError} error
 *
 * @typedef RolesInitialState
 * @property {object[]} roles
 * @property {boolean} isRolesLoading
 * @property {RoleDeletion} roleDeletion
 * @property {RoleAddition} roleAddition
 * @property {EditRole} editRole
 * @property {import("@reduxjs/toolkit").SerializedError} rolesError
 * @property {boolean} isFirst
 * @property {boolean} isLast
 * @property {number} pageNumber
 * @property {number} pageSize
 * @property {number} totalElements
 * @property {number} totalPages
 * @property {RoleTemplate} roleTemplate
 * @property {boolean} isApprovalThresholdsSettingLoading
 * @property {import("../../api/endpoints/roles").ApprovalThreshold} roleLimitation
 * @property {boolean} shouldFetchRoleDetails
 * @property {import("../../api/endpoints/roles").GetRoleDetailsResponse} roleDetails
 * @property {boolean} isRoleDetailsLoading
 * @property {import("@reduxjs/toolkit").SerializedError} roleDetailsError
 * @property {string} name
 */

/**
 * @typedef RoleAddition
 * @property {import("../../shared/constants/roles/add-role").RoleAdditionState} state
 * @property {boolean} isRoleCreationLoading
 * @property {string} roleId
 * @property {import("@reduxjs/toolkit").SerializedError} error
 * @property {import("../../api/endpoints/roles").roleFeatures} roleFeatures
 * @property {boolean} isConfirmed
 */

/**
 * @typedef EditRole
 * @property {import("../../shared/constants/roles/edit-role").EDIT_ROLE_STATE} state
 * @property {boolean} isEditingRoleLoading
 * @property {string} roleId
 * @property {import("@reduxjs/toolkit").SerializedError} error
 * @property {import("../../api/endpoints/roles").roleFeatures} roleFeatures
 * @property {boolean} isConfirmed
 * @property {import("../../api/endpoints/roles").GetRoleDetailsResponse} initialRoleDetails
 */

/**
 * @typedef RoleTemplate
 * @property {boolean} isRoleTemplateLoading
 * @property {import("../../api/endpoints/roles").RolesPermissionTemplate} roleTemplate
 * @property {import("@reduxjs/toolkit").SerializedError} roleTemplateError
 */

/**
 * @type {RolesInitialState}
 */

export const initialState = {
  roles: undefined,
  isRolesLoading: false,
  rolesError: undefined,
  isFirst: false,
  isLast: false,
  pageNumber: undefined,
  pageSize: undefined,
  totalElements: undefined,
  totalPages: undefined,

  roleDeletion: {
    state: ROLE_DELETION_STATE.IDLE,
    id: undefined,
    name: undefined,
    error: undefined,
  },

  roleAddition: {
    state: ROLE_ADDITION_STATE.IDLE,
    isRoleCreationLoading: false,

    roleId: undefined,
    error: undefined,
    roleFeatures: undefined,

    /**
     * @description whether the user confirmed the role addition
     * to be authorized to add a role
     */
    isConfirmed: false,
  },

  editRole: {
    state: EDIT_ROLE_STATE.IDLE,
    isEditingRoleLoading: false,

    roleId: undefined,
    error: undefined,
    roleFeatures: undefined,

    /**
     * @description whether the user confirmed the role addition
     * to be authorized to add a role
     */
    isConfirmed: false,

    initialRoleDetails: undefined,
  },

  roleTemplate: {
    isRoleTemplateLoading: false,
    roleTemplate: undefined,
    roleTemplateError: undefined,
  },

  name: undefined,

  roleLimitation: undefined,
  isApprovalThresholdsSettingLoading: false,

  /**
   * @description whether to fetch roleDetails in CONFIRMATION state
   */
  shouldFetchRoleDetails: false,

  roleDetails: undefined,
  isRoleDetailsLoading: false,
  roleDetailsError: undefined,
}

const rolesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    // -------- role details -------- \\
    resetRoleDetails: (state) => {
      state.roleDetails = initialState.roleDetails
      state.roleDetailsError = initialState.roleDetailsError
    },
    // -------- delete role -------- \\
    /**
     * @param {import("@reduxjs/toolkit").PayloadAction<{
     *  id: string,
     *  name: string,
     * }>} action
     */
    roleDeletionStarted: (state, action) => {
      state.roleDeletion.state = ROLE_DELETION_STATE.STARTED
      state.roleDeletion.id = action.payload.id
      state.roleDeletion.name = action.payload.name
    },
    roleDeletionRejected: (state) => {
      state.roleDeletion.state = ROLE_DELETION_STATE.REJECTED
    },
    roleDeletionAuthorizing: (state) => {
      state.roleDeletion.state = ROLE_DELETION_STATE.AUTHORIZING
    },
    roleDeletionAborting: (state) => {
      state.roleDeletion.state = ROLE_DELETION_STATE.ABORTING
    },
    roleDeletionFinished: (state) => {
      state.roleDeletion = initialState.roleDeletion
    },

    // -------- add role -------- \\
    roleAdditionStarted: (state) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.STARTED
      state.name = undefined
    },
    roleAdditionFinished: (state) => {
      state.roleAddition = initialState.roleAddition
      state.roleDetails = undefined
      state.roleLimitation = undefined
      state.shouldFetchRoleDetails = false
      state.name = undefined
    },
    roleAdditionPermissionSet: (state) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.PERMISSION_SET
    },
    roleAdditionAuthorizing: (state) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.AUTHORIZING
      state.roleAddition.isConfirmed = true
    },
    roleAdditionRejected: (state) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.REJECTED
    },
    roleAdditionAborting: (state) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.ABORTING
    },
    roleChangedName: (state, action) => {
      state.name = action.payload
    },
    roleAdditionConfirmation: (state) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.CONFIRMATION
      state.shouldFetchRoleDetails = false
    },
    // -------- edit role -------- \\
    editingRoleStarted: (state) => {
      state.editRole.state = EDIT_ROLE_STATE.STARTED
      state.name = undefined
    },
    editingRoleFinished: (state) => {
      state.editRole = initialState.editRole
      state.roleDetails = undefined
      state.roleLimitation = undefined
      state.shouldFetchRoleDetails = false
      state.name = undefined
    },
    editingRolePermissionSet: (state) => {
      state.editRole.state = EDIT_ROLE_STATE.PERMISSION_SET
    },
    editingRoleAuthorizing: (state) => {
      state.editRole.state = EDIT_ROLE_STATE.AUTHORIZING
      state.editRole.isConfirmed = true
    },
    editingRoleRejected: (state) => {
      state.editRole.state = EDIT_ROLE_STATE.REJECTED
    },
    editingRoleAborting: (state) => {
      state.editRole.state = EDIT_ROLE_STATE.ABORTING
    },
    editingRoleConfirmation: (state) => {
      state.editRole.state = EDIT_ROLE_STATE.CONFIRMATION
      state.shouldFetchRoleDetails = false
    },
    editingRoleInitialRoleDetails: (state, action) => {
      state.editRole.initialRoleDetails = action.payload
    },
  },
  extraReducers: (builder) => {
    // -------- get role template -------- \\
    builder.addCase(fetchRolePermissionsTemplate.pending, (state) => {
      state.roleTemplate.isRoleTemplateLoading = true
    })
    builder.addCase(fetchRolePermissionsTemplate.fulfilled, (state, action) => {
      state.roleTemplate.isRoleTemplateLoading = false
      state.roleTemplate.roleTemplate = action.payload.data.features
    })
    builder.addCase(fetchRolePermissionsTemplate.rejected, (state, action) => {
      state.roleTemplate.isRoleTemplateLoading = false
      state.roleTemplate.roleTemplateError = action.error
    })
    // -------- get roles -------- \\
    builder.addCase(fetchRoles.pending, (state) => {
      state.isRolesLoading = true
    })
    builder.addCase(fetchRoles.fulfilled, (state, action) => {
      state.isRolesLoading = false
      state.roles = action.payload.data.content
      state.isFirst = action.payload.data.first
      state.isLast = action.payload.data.last
      state.pageNumber = action.payload.data.pageNumber
      state.pageSize = action.payload.data.pageSize
      state.totalElements = action.payload.data.totalElements
      state.totalPages = action.payload.data.totalPages
    })
    builder.addCase(fetchRoles.rejected, (state, action) => {
      state.isRolesLoading = false
      state.rolesError = action.error
    })
    builder.addCase(editingRole.pending, (state) => {
      state.editRole.isEditingRoleLoading = true
    })
    builder.addCase(editingRole.fulfilled, (state, action) => {
      state.editRole.state = EDIT_ROLE_STATE.PERMISSION_SET
      state.editRole.roleId = action.payload.data.id
      state.name = action.meta.arg.name
      state.editRole.roleFeatures = action.meta.arg
      state.editRole.isEditingRoleLoading = false
    })
    builder.addCase(editingRole.rejected, (state, action) => {
      state.editRole.state = EDIT_ROLE_STATE.ERRORED
      state.editRole.error = action.error
      state.editRole.isEditingRoleLoading = false
    })
    // -------- add role -------- \\
    builder.addCase(roleCreation.pending, (state) => {
      state.roleAddition.isRoleCreationLoading = true
    })
    builder.addCase(roleCreation.fulfilled, (state, action) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.PERMISSION_SET
      state.roleAddition.roleId = action.payload.data.id
      state.name = action.meta.arg.name
      state.roleAddition.roleFeatures = action.meta.arg
      state.roleAddition.isRoleCreationLoading = false
    })
    builder.addCase(roleCreation.rejected, (state, action) => {
      state.roleAddition.state = ROLE_ADDITION_STATE.ERRORED
      state.roleAddition.error = action.error
      state.roleAddition.isRoleCreationLoading = false
    })
    builder.addCase(roleApprovalThresholdsSetting.pending, (state) => {
      state.isApprovalThresholdsSettingLoading = true
    })

    builder.addCase(
      roleApprovalThresholdsSetting.fulfilled,
      (state, action) => {
        const { arg } = action.meta

        if (arg.operationType === ROLE_ADDITION_OPERATION_ID) {
          state.roleAddition.state = ROLE_ADDITION_STATE.CONFIRMATION
          state.roleAddition.isConfirmed = false
        } else if (arg.operationType === EDIT_ROLE_OPERATION_ID) {
          state.editRole.state = EDIT_ROLE_STATE.CONFIRMATION
          state.editRole.isConfirmed = false
        }
        state.name = arg.name
        state.roleLimitation = arg
        state.shouldFetchRoleDetails = true
        state.isApprovalThresholdsSettingLoading = false
      }
    )

    builder.addCase(roleApprovalThresholdsSetting.rejected, (state, action) => {
      const { arg } = action.meta
      const error = { message: "web_c_general_error_massage" }

      if (Array.isArray(action.payload?.errors)) {
        const { detail } = action.payload.errors.pop()
        error.message = detail
      } else {
        error.message = action.error.message
      }

      if (arg.operationType === ROLE_ADDITION_OPERATION_ID) {
        state.roleAddition.state = ROLE_ADDITION_STATE.ERRORED
        state.roleAddition.error = error
      } else if (arg.operationType === EDIT_ROLE_OPERATION_ID) {
        state.editRole.state = EDIT_ROLE_STATE.ERRORED
        state.editRole.error = error
      }
      state.isApprovalThresholdsSettingLoading = false
    })
    builder.addCase(roleAuthorization.fulfilled, (state, action) => {
      const { operationType } = action.meta.arg

      if (operationType === ROLE_ADDITION_OPERATION_ID) {
        state.roleAddition.state = ROLE_ADDITION_STATE.ADDED
      } else if (operationType === EDIT_ROLE_OPERATION_ID) {
        state.editRole.state = EDIT_ROLE_STATE.EDITED
      }
    })

    builder.addCase(roleAuthorization.rejected, (state, action) => {
      const { operationType } = action.meta.arg
      const error = { message: "web_c_general_error_massage" }

      if (Array.isArray(action.payload?.errors)) {
        const { detail } = action.payload.errors.pop()
        error.message = detail
      } else {
        error.message = action.error.message
      }

      if (operationType === ROLE_ADDITION_OPERATION_ID) {
        state.roleAddition.state = ROLE_ADDITION_STATE.ERRORED
        state.roleAddition.error = error
      } else if (operationType === EDIT_ROLE_OPERATION_ID) {
        state.editRole.state = EDIT_ROLE_STATE.ERRORED
        state.editRole.error = error
      }
    })
    // -------- delete role -------- \\
    builder.addCase(roleDeletion.fulfilled, (state) => {
      state.roleDeletion.state = ROLE_DELETION_STATE.DELETED
    })
    builder.addCase(roleDeletion.rejected, (state, action) => {
      state.roleDeletion.state = ROLE_DELETION_STATE.ERRORED
      state.roleDeletion.error = action.error
    })
    // ------- get role details -------- \\
    builder.addCase(fetchRoleDetails.pending, (state) => {
      state.isRoleDetailsLoading = true
    })
    builder.addCase(fetchRoleDetails.fulfilled, (state, action) => {
      state.isRoleDetailsLoading = false
      state.roleDetails = action.payload.data
    })
    builder.addCase(fetchRoleDetails.rejected, (state, action) => {
      state.isRoleDetailsLoading = false
      state.roleDetailsError = action.error
    })
  },
})

export const {
  // -------- Add Role -------- \\
  roleAdditionStarted,
  roleAdditionFinished,
  roleAdditionPermissionSet,
  roleAdditionAuthorizing,
  roleAdditionRejected,
  roleAdditionAborting,
  roleChangedName,
  roleAdditionConfirmation,
  // -------- Edit Role -------- \\
  editingRoleStarted,
  editingRoleFinished,
  editingRolePermissionSet,
  editingRoleAuthorizing,
  editingRoleRejected,
  editingRoleAborting,
  editingRoleConfirmation,
  editingRoleInitialRoleDetails,
  // -------- role details -------- \\
  resetRoleDetails,
  // -------- delete role -------- \\
  roleDeletionStarted,
  roleDeletionRejected,
  roleDeletionAuthorizing,
  roleDeletionAborting,
  roleDeletionFinished,
} = rolesSlice.actions

export default rolesSlice.reducer
