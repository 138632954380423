/**
 * `context` value used when asking BE for an OTAC
 * for login purposes.
 */
export const OTAC_CONTEXT_LOGIN = "LOGIN"

/**
 * `context` value used when asking BE for an OTAC
 * for creating a terminal.
 */
export const OTAC_CONTEXT_CREATE_TERMINAL = "CREATE_TERMINAL"

/**
 * `context` value used when for an OTAC
 * for deleting a terminal
 */
export const OTAC_CONTEXT_DELETE_TERMINAL = "DELETE_TERMINAL"

/**
 * `context` value used when for an OTAC
 * for hiding a terminal from agents
 */
export const OTAC_CONTEXT_HIDE_TERMINAL = "HIDE_TERMINAL"

/**
 * `context` value used when for an OTAC
 * for showing a terminal to agents
 */
export const OTAC_CONTEXT_SHOW_TERMINAL = "UNHIDE_TERMINAL"

/**
 * `context` value used when asking BE for an OTAC
 * for changing terminal password.
 */
export const OTAC_CONTEXT_CHANGE_TERMINAL_PASSWORD = "CHANGE_TERMINAL_PASSWORD"

/**
 * `context` value used when asking BE for an OTAC
 * for shifting money between terminals.
 */
export const OTAC_CONTEXT_MAKE_TERMINAL_TRANSFER = "MAKE_TERMINAL_TRANSFER"

/**
 * the status of an OTAC that is created but yet to be
 * authorized to be used.
 */
export const OTAC_STATUS_CREATED = "CREATED"

/**
 * the status of an OTAC that is authorized and it's
 * corresponding `elevatedToken` is received and ready
 * to be used for a sensitive action (i.e transactions)
 */
export const OTAC_STATUS_AUTHORIZED = "AUTHORIZED"

/**
 * the status for OTAC that is rejected
 */
export const OTAC_STATUS_REJECTED = "REJECTED"

/**
 * `context` value used when asking BE for an OTAC
 * for adding a new employee.
 */
export const OTAC_CONTEXT_ADD_EMPLOYEE = "ADD_EMPLOYEE"

/**
 * `context` value used when asking BE for an OTAC
 * for editing an existing employee.
 */
export const OTAC_CONTEXT_EDIT_EMPLOYEE = "EDIT_EMPLOYEE"

/**
 * `context` value used when asking BE for an OTAC
 * for deleting employee.
 */
export const OTAC_CONTEXT_DELETE_EMPLOYEE = "DELETE_EMPLOYEE"

/**
 * `context` value used when asking BE for an OTAC
 * for deleting a team.
 */
export const OTAC_CONTEXT_DELETE_TEAM = "DELETE_TEAM"

/**
 * `context` value used when asking BE for an OTAC
 * for adding team.
 */
export const OTAC_CONTEXT_ADD_TEAM = "ADD_TEAM"

/**
 * `context` value used when asking BE for an OTAC
 * for editing team.
 */
export const OTAC_CONTEXT_EDIT_TEAM = "EDIT_TEAM"

/**
 * `context` value used when asking BE for an OTAC
 * for editing team.
 */
export const OTAC_CONTEXT_AUTHORIZE_SALARY_DISTRIBUTION =
  "AUTHORIZE_SALARY_DISTRIBUTION"

/**
 * `context` value used when asking BE for an OTAC
 * for deleting team member from a team.
 */
export const OTAC_CONTEXT_DELETE_EMPLOYEE_FROM_TEAM =
  "DELETE_EMPLOYEE_FROM_TEAM"

/**
 * `context` value used when asking BE for an OTAC
 * for making a bank transfer.
 */
export const OTAC_CONTEXT_AUTHORIZE_BANK_TRANSFER = "AUTHORIZE_BANK_TRANSFER"

/**
 * `context` value used when asking BE for an OTAC
 * for making a bulk transfer using CSV files
 */

export const OTAC_CONTEXT_AUTHORIZE_BULK_TRANSFER = "AUTHORIZE_BULK_TRANSFER"

/**
 * `context` value used when asking BE for an OTAC
 * for deleting user.
 */
// FIXME this could be change in case BE changed the context
export const OTAC_CONTEXT_DELETE_USER = "DELETE_AGENT"

/**
 * `context` value used when asking BE for an OTAC
 * for deleting role.
 */
export const OTAC_CONTEXT_DELETE_ROLE = "DELETE_ROLE"

/**
 * `context` value used when asking BE for an OTAC
 * for creating a user account.
 */
export const OTAC_CONTEXT_CREATE_AGENT = "CREATE_AGENT"

/**
 * `context` value used when asking BE for an OTAC
 * for adding a new role.
 */
export const OTAC_CONTEXT_ADD_ROLE = "ADD_ROLE"

/**
 * `context` value used when asking BE for an OTAC
 * for editing a role.
 */
export const OTAC_CONTEXT_EDIT_ROLE = "EDIT_ROLE"

/**
 * `context` value used when asking BE for an OTAC
 * to blocking an agent user.
 */
export const OTAC_CONTEXT_BLOCK_AGENT = "BLOCK_AGENT"

/**
 * `context` value used when asking BE for an OTAC
 * to unblock agent user.
 */
export const OTAC_CONTEXT_UNBLOCK_AGENT = "UNBLOCK_AGENT"

/**
 * `context` values used when asking BE for an OTAC
 * to approve pending processes that are Shift Money,
 * Salary Distribution, Bank Transfer, Bulk Transfer
 */
export const OTAC_CONTEXT_APPROVE_TERMINAL_TRANSFER =
  "APPROVE_TERMINAL_TRANSFER"
export const OTAC_CONTEXT_APPROVE_SALARY_DISTRIBUTION =
  "APPROVE_SALARY_DISTRIBUTION"
export const OTAC_CONTEXT_APPROVE_BANK_TRANSFER = "APPROVE_BANK_TRANSFER"
export const OTAC_CONTEXT_APPROVE_BULK_TRANSFER = "APPROVE_BULK_TRANSFER"

/**
 * `context` values used when asking BE for an OTAC
 * to reject pending processes that are Shift Money,
 * Salary Distribution, Bank Transfer, Bulk Transfer
 */
export const OTAC_CONTEXT_REJECT_TERMINAL_TRANSFER = "REJECT_TERMINAL_TRANSFER"
export const OTAC_CONTEXT_REJECT_SALARY_DISTRIBUTION =
  "REJECT_SALARY_DISTRIBUTION"
export const OTAC_CONTEXT_REJECT_BANK_TRANSFER = "REJECT_BANK_TRANSFER"
export const OTAC_CONTEXT_REJECT_BULK_TRANSFER = "REJECT_BULK_TRANSFER"
