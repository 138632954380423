/**
 * @typedef RoleAdditionState
 * @property {string} IDLE
 * @property {string} STARTED
 * @property {string} PERMISSION_SET
 * @property {string} AUTHORIZING
 * @property {string} ABORTING
 * @property {string} REJECTED
 * @property {string} ADDED
 * @property {string} ERRORED
 * @property {string} CONFIRMATION
 */

/**
 * @type {RoleAdditionState}
 * @enum {string}
 */
export const ROLE_ADDITION_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  PERMISSION_SET: "PERMISSION_SET",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  ADDED: "ADDED",
  ERRORED: "ERRORED",
  CONFIRMATION: "CONFIRMATION",
}

export const getStepNumberFromRoleAdditionState = (roleAdditionState) => {
  switch (roleAdditionState) {
    case ROLE_ADDITION_STATE.STARTED:
      return 0
    case ROLE_ADDITION_STATE.PERMISSION_SET:
      return 1
    case ROLE_ADDITION_STATE.AUTHORIZING:
    case ROLE_ADDITION_STATE.ABORTING:
    case ROLE_ADDITION_STATE.REJECTED:
    case ROLE_ADDITION_STATE.ERRORED:
    case ROLE_ADDITION_STATE.ADDED:
    case ROLE_ADDITION_STATE.CONFIRMATION:
      return 2
    default:
      return -1
  }
}

export const ROLE_ADDITION_PERMISSION_STEP_FORM_ID =
  "role-addition-permission-step-form"

export const ROLE_ADDITION_OPERATION_ID = "ROLE_ADDITION"
