import { mockedRolesWithApprovalThresholds } from "../../mocks/roleDetails"
import {
  MAP_AGENT_FEATURES_TO_ROUTES,
  PERMISSIONS,
} from "../../shared/constants/agent"

/**
 *
 * @param {Array<Object<string, string>>} permissions
 * @param {string} permissionName
 * @returns {boolean}
 */
export const isPermissionGranted = (permissions, permissionName) =>
  permissions?.some(({ name, granted }) => name === permissionName && granted)

export function getFirstRouteUrlWithViewPermission(agentRoleFeatures) {
  const firstAgentFeatureWithViewPermission = agentRoleFeatures?.find(
    (features) => isPermissionGranted(features.permissions, PERMISSIONS.VIEW)
  )
  return MAP_AGENT_FEATURES_TO_ROUTES[firstAgentFeatureWithViewPermission?.name]
}

export function checkFeatureHasPermission(
  agentRoleFeatures,
  passedFeature,
  permissionName
) {
  const agentFeaturesWithPermissionName = agentRoleFeatures?.filter((feature) =>
    isPermissionGranted(feature.permissions, permissionName)
  )

  return agentFeaturesWithPermissionName?.some(
    (feature) => feature.name === passedFeature
  )
}

export function modifyGroupLinksByPermissions(agentRoleFeatures, groupLinks) {
  const agentFeaturesWithViewPermission = agentRoleFeatures?.filter((feature) =>
    isPermissionGranted(feature.permissions, PERMISSIONS.VIEW)
  )

  const routesWithViewPermission = []
  agentFeaturesWithViewPermission?.forEach((feature) => {
    const route = MAP_AGENT_FEATURES_TO_ROUTES[feature.name]
    routesWithViewPermission.push(route)
  })
  const uniqueRoutesWithViewPermission = [...new Set(routesWithViewPermission)]
  const newGroupLinks = groupLinks.filter((link) =>
    uniqueRoutesWithViewPermission.includes(link.to)
  )

  return newGroupLinks
}

export function shouldRenderRoute(
  isMainAccount,
  agentRoleFeatures,
  passedFeatures
) {
  return (
    isMainAccount ||
    passedFeatures.some((feature) =>
      checkFeatureHasPermission(agentRoleFeatures, feature, PERMISSIONS.VIEW)
    )
  )
}

export const shouldRenderNavLinkGroup = (isMainAccount, groupLinks) =>
  isMainAccount || groupLinks.length !== 0

export const getPermissionsByFeatureName = (agentRoleFeatures, featureName) => {
  const featurePermissions = agentRoleFeatures?.find(
    (agentFeature) => agentFeature.name === featureName
  )?.permissions

  return featurePermissions
}

/**
 *
 * @param {boolean} isMainAccount
 * @param {import("../../api/endpoints/roles").roleFeatures[]} roleFeature
 * @param {string} featureName
 * @returns {{
 *   ADD: boolean,
 *   VIEW: boolean,
 *   EDIT: boolean,
 *   DELETE: boolean,
 * }}
 */
export const permissionTypeBuilder = (
  isMainAccount,
  roleFeatures,
  featureName
) => {
  const permissions = getPermissionsByFeatureName(roleFeatures, featureName)

  const clonedPermissions = { ...PERMISSIONS }
  Object.keys(clonedPermissions).forEach((key) => {
    if (isMainAccount === true) {
      clonedPermissions[key] = true
    } else if (!permissions) {
      clonedPermissions[key] = false
    } else {
      clonedPermissions[key] = isPermissionGranted(permissions, key)
    }
  })
  return clonedPermissions
}

/**
 * @param {import("../../api/endpoints/roles").ApprovalThreshold[]} approvalThresholds
 * @param {import("../../api/endpoints/roles").ApprovalThresholdType} thresholdType
 * @param {import("../../shared/constants/bank-accounts").CURRENCY_TYPE} currency
 * @returns {number | null}
 * @description returns the amount of the threshold.
 * when it is null, it means it is UNLIMITED and there is no amount
 */
export const getAgentApprovalThresholdAmount = (
  approvalThresholds,
  thresholdType,
  currency
) => {
  const threshold = approvalThresholds?.find(
    (item) => item.type === thresholdType
  )
  if (!threshold) return null

  const currencyThreshold = threshold.byCurrency.find(
    (byCurrency) => byCurrency.currency === currency
  )
  if (!currencyThreshold) return null

  return currencyThreshold.value?.amount || null
}
/**
 *
 * @param {string} featureName
 * @param {string} thresholdType
 * @returns {{
 *  feature: import("../../api/endpoints/roles").roleFeatures[],
 * approvalThresholds: import("../../api/endpoints/roles").ApprovalThreshold[]
 * }}
 * @description returns the feature and the threshold mainly used for testing purposes
 */
export function getFeatureWithThreshold(featureName, thresholdType) {
  return {
    feature: [
      mockedRolesWithApprovalThresholds.features.find(
        (feat) => feat.name === featureName
      ),
    ],
    approvalThresholds: [
      mockedRolesWithApprovalThresholds.approvalThresholds.find(
        (threshold) => threshold.type === thresholdType
      ),
    ],
  }
}
