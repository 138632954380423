/**
 * @typedef EditRoleState
 * @property {string} IDLE
 * @property {string} STARTED
 * @property {string} PERMISSION_SET
 * @property {string} AUTHORIZING
 * @property {string} ABORTING
 * @property {string} REJECTED
 * @property {string} EDITED
 * @property {string} ERRORED
 * @property {string} CONFIRMATION
 */

/**
 * @type {EditRoleState}
 * @enum {string}
 */
export const EDIT_ROLE_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  PERMISSION_SET: "PERMISSION_SET",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  EDITED: "EDITED",
  ERRORED: "ERRORED",
  CONFIRMATION: "CONFIRMATION",
}

export const getStepNumberFromEditRoleState = (editRoleState) => {
  switch (editRoleState) {
    case EDIT_ROLE_STATE.STARTED:
      return 0
    case EDIT_ROLE_STATE.PERMISSION_SET:
      return 1
    case EDIT_ROLE_STATE.AUTHORIZING:
    case EDIT_ROLE_STATE.ABORTING:
    case EDIT_ROLE_STATE.REJECTED:
    case EDIT_ROLE_STATE.ERRORED:
    case EDIT_ROLE_STATE.EDITED:
    case EDIT_ROLE_STATE.CONFIRMATION:
      return 2
    default:
      return -1
  }
}

export const EDIT_ROLE_PERMISSION_STEP_FORM_ID =
  "edit-role-permission-step-form"

export const EDIT_ROLE_OPERATION_ID = "EDIT_ROLE"
