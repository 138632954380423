import AxiosInstance from "../axios"

/**
 *
 * @param {string} id
 * @param {import("axios").AxiosRequestConfig} config
 */
export const deleteRole = (id, config) =>
  AxiosInstance.delete(`/v1/corporate-users/me/roles/${id}`, config)

// -----------  Add Role  ----------- //
/**
 * @typedef { "VIEW" | "ADD" | "EDIT" | "DELETE" } PermissionNames
 * @typedef { "DASHBOARD" | "BALANCE" | "OPERATION_HISTORY" | "TERMINALS" |
 * "SHIFT_MONEY" | "EMPLOYEES" | "TEAMS" | "TEAM_SALARY_DISTRIBUTIONS" |
 * "FILE_SALARY_DISTRIBUTIONS" | "BANK_TRANSFERS" | "RECEIVE_PAYMENTS" | "ROLES" | "AGENTS"} FeaturesNames
 */

/**
 * @typedef {object} RolesPermissionTemplate
 *
 * @property {object[]} features
 * @property {FeaturesNames} features.name
 * @property {string} features.translationKey
 * @property {object[]} features.permissions
 * @property {PermissionNames} features.permissions.name
 * @property {string} features.permissions.translationKey
 * @property {boolean} features.permissions.applicable
 * @property {PermissionNames[]} features.permissions.dependsOn
 *
 * @returns { Promise<import("axios").AxiosResponse<RolesPermissionTemplate>> }
 */
export const getRolesPermissionTemplate = () =>
  AxiosInstance.get("/v1/corporate-roles/template")

/**
 * @typedef {object} roleFeatures
 *
 * @property {string} name
 * @property {object[]} features
 * @property {FeaturesNames} features.name
 * @property {object[]} features.permissions
 * @property {PermissionNames} features.permissions.name
 * @property {boolean} features.permissions.granted
 *
 * @param {roleFeatures} data
 *
 * @returns { Promise<import("axios").AxiosResponse<{id: string}>> }
 */
export const createRole = (data) =>
  AxiosInstance.post("/v1/corporate-users/me/roles", data)

/**
 * @param {string} roleId
 * @param {roleFeatures} data
 *
 * @returns { Promise<import("axios").AxiosResponse<{id: string}>> }
 */
export const updateRole = (roleId, data) =>
  AxiosInstance.post(`/v1/corporate-users/me/roles/${roleId}`, data)

/**
 * @typedef {"SHIFT_MONEY" | "SALARY_DISTRIBUTIONS" | "BANK_TRANSFERS" | "RECEIVE_PAYMENTS" | "BULK_TRANSFERS"} ApprovalThresholdType
 * @typedef {object} ApprovalThreshold
 *
 * @property {string} name
 * @property {object[]} approvalThresholds
 * @property {ApprovalThresholdType} approvalThresholds.type
 * @property {object[]} approvalThresholds.byCurrency
 * @property {import("../../shared/constants/bank-accounts").CURRENCY_TYPE} approvalThresholds.byCurrency.currency
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} approvalThresholds.byCurrency.value
 *
 * @param {string} id
 * @param {ApprovalThreshold} data
 */
export const setRoleApprovalThresholds = (id, data) =>
  AxiosInstance.post(
    `/v1/corporate-users/me/roles/${id}/approval-thresholds`,
    data
  )

/**
 * @param {string} id
 * @param {import("axios").AxiosRequestConfig} config
 */
export const authorizeRole = (id, config) =>
  AxiosInstance.post(
    `/v1/corporate-users/me/roles/${id}/authorize`,
    null,
    config
  )

export const getRoles = ({ page, size }) => {
  const params = new URLSearchParams()
  params.set("page", page)
  params.set("size", size)

  return AxiosInstance.get(`/v1/corporate-users/me/roles?${params}`)
}
/**
 * @typedef { "VIEW" | "ADD" | "EDIT" | "DELETE" } PermissionNames
 * @typedef { "DASHBOARD" | "BALANCE" | "OPERATION_HISTORY" | "TERMINALS" |
 * "SHIFT_MONEY" | "EMPLOYEES" | "TEAMS" | "TEAM_SALARY_DISTRIBUTIONS" |
 * "FILE_SALARY_DISTRIBUTIONS" | "BANK_TRANSFERS" | "RECEIVE_PAYMENTS" | "ROLES" | "AGENTS"} FeaturesNames
 * @typedef {"DRAFT" | "ACTIVE" | "DELETED" | "TEMPORARY"} RoleStatus
 */

/**
 * @typedef {object} GetRoleDetailsResponse
 * @property {string} id
 * @property {string} corporateUserId
 * @property {RoleStatus} status
 * @property {string} name
 * @property {object[]} features
 * @property {FeaturesNames} features.name
 * @property {string} features.translationKey
 * @property {object[]} features.permissions
 * @property {PermissionNames} features.permissions.name
 * @property {string} features.permissions.translationKey
 * @property {boolean} features.permissions.applicable
 * @property {boolean} features.permissions.granted
 * @property {PermissionNames[]} features.permissions.dependsOn
 * @property {object[]} approvalThresholds
 * @property {ApprovalThresholdType} approvalThresholds.type
 * @property {string} approvalThresholds.translationKey
 * @property {object[]} approvalThresholds.byCurrency
 * @property {import("../../shared/constants/bank-accounts").CURRENCY_TYPE} approvalThresholds.byCurrency.currency
 * @property {object} approvalThresholds.byCurrency.value
 * @property {number} approvalThresholds.byCurrency.value.amount
 * @property {import("../../shared/constants/bank-accounts").CURRENCY_TYPE} approvalThresholds.byCurrency.value.currency
 *
 * @param {string} id
 * @returns { Promise<import("axios").AxiosResponse<GetRoleDetailsResponse>> }
 */
export const getRoleDetails = (id) =>
  AxiosInstance.get(`/v1/corporate-users/me/roles/${id}`)
